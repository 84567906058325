import React from "react"
import Footer from "../components/footer"
import "../styles/ethics.less"
import SEO from "../components/SEO"
import Header from "../components/header"
import Button from "../components/ui/button"
import { lang } from "../utils"
// import { SolutionTopBanner } from "../components/block"
// import { SupportPopButton } from "../components/form-biz"
const loadText = require("src/utils").loadText
const data = loadText("ethics")
const Joe = () => {
  return (
    <div className="joe">
      <picture>
        <source srcSet="/ethics/joe-m.jpg" media="(max-width: 640px)" />
        <img src="/ethics/joe.jpg" alt="CEO" />
      </picture>
      <div className="right">
        {data.joe.content.map(text => (
          <div className="content">{text}</div>
        ))}
        <div className="name">{data.joe.name}</div>
        <div className="title">{data.joe.title}</div>
        <div className="company">{data.joe.company}</div>
      </div>
    </div>
  )
}
const Concern = () => (
  <div className="concern">
    <div className="concern-bg">
      <div className="concern-content">
        <div className="left">
          <div className="title">{data.concern.title}</div>
          <a
            href=" https://app.convercent.com/en-US/LandingPage/f89a491e-62f5-eb11-a983-000d3ab9f062"
            className="content"
          >
            上海层峰 Ethics Portal
          </a>
          {data.concern.content.map(text => (
            <div className="content">{text}</div>
          ))}
        </div>
        <div className="right">
          <div className="btn1">
            <Button
              width="100%"
              className="white"
              size="l"
              onClick={() => {
                window.open(
                  `/Employee Code of Conduct${lang === "zh" ? "-zh" : ""}.pdf`
                )
              }}
            >
              <div className="btn-text">
                <img src="/ethics/download-b.svg" alt="" />
                {data.concern.btn1}
              </div>
            </Button>
          </div>
          <div>
            <Button
              width="100%"
              size="l"
              onClick={() => {
                window.open(
                  `/Global Anti-Corruption Policy 2021${
                    lang === "zh" ? "-zh" : ""
                  }.pdf`
                )
              }}
            >
              <div className="btn-text">
                <img src="/ethics/download-w.svg" alt="" />
                {data.concern.btn2}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
)
const TopBanner = () => {
  return (
    <div className="topBanner">
      <div className="topBanner-bg">
        <div className="headerContainer">
          <Header logo="/logo-blue.png" />
        </div>
        <div className="title">{data.topBanner.title}</div>
      </div>
    </div>
  )
}
export default function Home() {
  return (
    <div className="ethics">
      <SEO {...data.seo} />
      <TopBanner />
      <Joe />
      <Concern />

      <Footer />
    </div>
  )
}
